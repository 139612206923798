import React, { useRef, useEffect } from 'react'
import { login, isAuthenticated } from '../../../utils/auth'
import { Pane, TextInputField } from 'evergreen-ui'
import { createUseStyles } from 'react-jss'
import {
  navStyles,
  customListStyles,
  tableSectionStyles
} from '../../../styles/sharedComponentStyles'
import CustomTable from '../../../components/CustomTable'
import { gridUserOptions } from '../../../gridConfig/usersGridConfig'
import { useHandleUsersList } from '../../../hooks/useHandleUsersList'
import { useGlobalStore } from '../../../hooks/useGlobalStore'
import { getRealmIdFromURL } from '../../../utils/filters'
import { useLocation } from '@reach/router'

const useSharedStyles = createUseStyles({
  ...navStyles,
  ...customListStyles,
  ...tableSectionStyles,
  usersTableAgGrid: {
    height: '93vh'
  }
})

const UsersPageNav = ({ usersSet, filterValue, handleUsersFilter }) => {
  const sharedClasses = useSharedStyles()

  return (
    <>
      <nav className={sharedClasses.nav}>
        <Pane className={sharedClasses.secondaryNav}>
          <h1 data-testid="main-title">Users</h1>
          <Pane className={sharedClasses.autoComplete} display="flex">
            {usersSet && (
              <TextInputField
                value={filterValue}
                label="Search for a User name or email"
                onChange={e => handleUsersFilter(e.target.value)}
                style={{
                  padding: '20px'
                }}
              />
            )}
          </Pane>
        </Pane>
      </nav>
    </>
  )
}

const UsersPage = () => {
  const sharedClasses = useSharedStyles()
  const { setRealmPath } = useGlobalStore()
  const usersSectionRef = useRef(null)
  const location = useLocation()
  const routeRealm = location
    ? Number(getRealmIdFromURL(location))
    : -1

  const {
    usersSet,
    filterValue,
    onGridReady,
    handleUserSelect,
    handleUsersFilter
  } = useHandleUsersList({ usersSectionRef, routeRealm })

  useEffect(() => {
    setRealmPath('users')
  }, [setRealmPath])

  if (!isAuthenticated()) {
    login()
    return (
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <h2>Redirecting to login...</h2>
      </Pane>
    )
  }

  return (
    <>
      <UsersPageNav
        usersSet={usersSet}
        filterValue={filterValue}
        handleUsersFilter={handleUsersFilter}
      />
      <Pane className={sharedClasses.tableContainer}>
        <CustomTable
          gridOptions={gridUserOptions}
          defaultColDef={{
            sortable: true,
            filter: true,
            flex: 2,
            floatingFilter: true,
            suppressMenu: true
          }}
          overlayLoadingTemplate={
            '<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
          }
          overlayNoRowsTemplate={
            '<span className="ag-overlay-loading-center">No data found to display.</span>'
          }
          tableStyleClasses={[sharedClasses.usersTableAgGrid]}
          onRowClicked={handleUserSelect}
          onGridReady={onGridReady}
          rowHeight={53}
          data={usersSet.filter(({ dummyObj }) => !dummyObj)}
          ref={usersSectionRef}
        />
      </Pane>
    </>
  )
}

export default UsersPage
